.Banner {
  background-size: contain, 20%;
  background-position: left, right;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .Banner {
    background-size: cover, 40%;
    background-position: left, 100% 95%;
    background-repeat: no-repeat;
  }
}
