@import "./index.css";

.p-inputtext {
  color: #ffffff;
  background: transparent;
}

.p-inputtext:enabled:hover {
  border-color: var(--jasmin-yellow) !important;
}

.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.2rem transparent !important;
  border-color: var(--jasmin-yellow) !important;
}

/* ==========================
   Botones (Buttons)
   ========================== */

.p-button.p-button-icon-only {
  background-color: var(--earth-yellow) !important;
  border-color: var(--jasmin-yellow) !important;
}

.p-button.p-button-outlined {
  background-color: transparent !important;
  border-color: var(--jasmin-yellow) !important;
  color: var(--jasmin-yellow);
  border: 1px solid;
  /* Borde sólido */
}

.p-button.p-button-outlined:not(:disabled):hover {
  color: #ffffff;
}

.p-button.p-button-text {
  background-color: transparent !important;
  border-color: transparent !important;
  color: var(--jasmin-yellow) !important;
}

.p-button {
  background-color: var(--earth-yellow) !important;
  border-color: var(--jasmin-yellow) !important;
}

.p-button:not(:disabled):hover {
  background-color: var(--earth-yellow) !important;
  border-color: var(--jasmin-yellow) !important;
  color: #ffffff !important;
}

.p-button-label {
  font-weight: 400 !important;
}

/* ==========================
   Colores Amarillos (Yellow Colors)
   ========================== */

.text-js-yellow {
  color: var(--jasmin-yellow) !important;
}

.bg-js-yellow {
  background-color: var(--jasmin-yellow) !important;
}

.border-js-yellow {
  border-color: var(--jasmin-yellow) !important;
}

.border-eh-yellow {
  color: var(--earth-yellow) !important;
}

.bg-eh-yellow {
  background-color: var(--earth-yellow) !important;
}

.text-eh-yellow {
  color: var(--earth-yellow) !important;
}

/* ==========================
   Colores Azules (Blue Colors)
   ========================== */

.bg-trl-blue {
  background-color: var(--blue-pop-bg-dark-translucent) !important;
}

.bg-trl-80-blue {
  background-color: var(--blue-pop-bg-dark-80-translucent) !important;
}

.bg-blue-dark {
  background-color: var(--blue-pop-bg-dark) !important;
}

.bg-blue {
  background-color: var(--blue-pop-bg-blue) !important;
}

.border-blue {
  border: var(--blue-pop-bg-dark);
}

/* ==========================
   Colores Dorados (Gold Colors)
   ========================== */

.border-pop-gold {
  color: var(--gold-popla) !important;
}

.bg-pop-gold {
  background-color: var(--gold-popla) !important;
}

.text-pop-gold {
  color: var(--gold-popla) !important;
}

/* ==========================
   Colores Amarillos Pop (Yellow Pop Colors)
   ========================== */

.border-pop-yellow {
  color: var(--yellow-popla) !important;
}

.bg-pop-yellow {
  background-color: var(--yellow-popla) !important;
}

.text-pop-yellow {
  color: var(--yellow-popla) !important;
}

/* ==========================
   Colores Grises (Gray Colors)
   ========================== */

.text-gray-500 {
  color: #999999 !important;
}

/* ==========================
   Sombra de Contenedor (Container Shadow)
   ========================== */

.container-shadow-js {
  box-shadow: inset 0 0 10px 5px var(--jasmin-yellow);
}

.container-shadow-js-os {
  box-shadow: 0 0 10px 3px var(--jasmin-yellow);
}

.bg-trl-black {
  background-color: #141414 !important;
}

.whatsapp-button {
  position: fixed;
  bottom: 0px;
  right: 20px;
  width: 60px;
  height: 60px;
  z-index: 1000;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Estilos para mostrar el botón cuando se ha hecho scroll */
.whatsapp-button.show {
  opacity: 1;
  transform: translateY(0);
}

/* Animación suave al hacer hover */
.whatsapp-button:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}

/* Estilo de la imagen */
.whatsapp-button img {
  width: 4rem;
  height: auto;
  border-radius: 50%;
}
