@import "primeflex/primeflex.css";

:root {
  --earth-yellow: #dfaa54;
  --jasmin-yellow: #f2cb6f;
  --blue-pop-bg-blue: #293543;
  --blue-pop-bg-dark: #1f2a33;
  --blue-pop-bg-dark-translucent: rgba(31, 42, 51, 0.8);
  --blue-pop-bg-dark-80-translucent: rgba(31, 42, 51, 0.4);

  --gold-popla: #e1c799;
  --yellow-popla: #c39800;
}

body {
  margin: 0;
  font-family: "Urbanist", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  background-color: var(--blue-pop-bg-blue);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.urbanist-thin {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

.urbanist-thin-italic {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: italic;
}

.urbanist-extralight {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.urbanist-extralight-italic {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: italic;
}

.urbanist-light {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.urbanist-light-italic {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: italic;
}

.urbanist-regular {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.urbanist-regular-italic {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: italic;
}

.urbanist-medium {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.urbanist-medium-italic {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: italic;
}

.urbanist-semibold {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.urbanist-semibold-italic {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: italic;
}

.urbanist-bold {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.urbanist-bold-italic {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: italic;
}

.urbanist-extrabold {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.urbanist-extrabold-italic {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: italic;
}

.urbanist-black {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

.urbanist-black-italic {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: italic;
}
