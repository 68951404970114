.OurTeam {
  /* Add your component styles here */
}

.card-team {
  transition: transform 0.3s ease, background-color 0.3s ease, border 0.3s ease;
}

.card-team:hover {
  border: 1px solid var(--jasmin-yellow) !important;
  background-color: var(--blue-pop-bg-dark-translucent) !important;
  transform: scale(1.05);
}

.card-team-selected {
  border: 1px solid var(--jasmin-yellow) !important;
  background-color: var(--blue-pop-bg-dark-translucent) !important;
}

@media screen and (min-width: 768px) {
  .custom-responsive-container {
    width: fit-content !important;
  }
}
