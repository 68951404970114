.cont-left {
  min-height: 18rem;
}

.solution {
  position: relative;
  background: linear-gradient(
    to right,
    var(--blue-pop-bg-dark-translucent),
    rgba(0, 0, 0, 0)
  );
}

.solution::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--yellow-popla);
  border-radius: 2px;
}
