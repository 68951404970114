.NavBar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: transparent;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.NavBar.scrolled {
  background-color: rgba(31, 42, 51, 0.9);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
